
import {
  defineComponent,
  ref,
  onMounted,
  onActivated,
  onUpdated,
  computed,
} from "vue";
import { MBDatatablePlus } from "magicbean-saas-common";
import FilterDropdwon from "./FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiFinacialReport } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import mixin from "@/mixins";
import {
  commonChangeFilterAddDate,
  commonExportFile,
  formatDate,
  setModuleBCN,
  settlementLastMonthDate,
  settlementLastMonthDateString,
  settlementLastWeekDate,
  settlementLastWeekDateString,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import {
  FilterOption,
  SortOption,
  TaggingItem,
} from "@/core/directive/interface/common";
import { checkPermissionFlag } from "@/directives/permission";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";
import IndexSubTable from "./IndexSubTable.vue";
import { OCPSSSReceivablesTable } from "@/core/directive/interface/finacialReport";
import { getOCPSSSReceivablesStatus } from "@/core/directive/function/finacialReport";

export default defineComponent({
  name: "transaction-report-finacial-report-ocps-ss-sales-receivables",
  components: {
    MBDatatablePlus,
    FilterDropdwon,
    PermissionCommon,
    IndexSubTable,
  },
  setup() {
    const { t } = useI18n();
    const { showServerErrorMsg } = mixin();
    const route = useRoute();

    const loading = ref(false);
    const filterRef = ref();
    const tableData = ref<Array<OCPSSSReceivablesTable>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const disabledExportSelected = ref(false);
    const disabledExportAll = ref(false);
    const disabledExportLastWeek = ref(false);
    const disabledExportLastMonth = ref(false);
    const checkedInvoice = ref<Array<number>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "created_at", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);
    const tableHeader = ref([
      {
        name: t("finacialReport.entity"),
        key: "entity",
        sortable: false,
      },
      {
        name: t("finacialReport.osInvoiceNo"),
        key: "os_invoice_number",
        sortable: false,
      },
      {
        name: t("finacialReport.osNo"),
        key: "os_number",
        sortable: false,
      },
      {
        name: t("finacialReport.osInvoicedAmount"),
        key: "os_invoiced_amount",
        sortable: false,
        align: "right",
      },
      {
        name: t("finacialReport.osReceivables"),
        key: "os_receivables",
        sortable: false,
        align: "right",
      },
      {
        name: t("finacialReport.customerPaidAmount"),
        key: "customer_paid_amount",
        sortable: false,
        align: "right",
      },
      {
        name: t("finacialReport.osInvoiceStatus"),
        key: "os_invoice_status",
        sortable: false,
        align: "right",
      },
      {
        name: t("finacialReport.osInvoiceDate"),
        key: "os_invoice_date",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      scource: "",
      scourceOptions: [] as TaggingItem[],
      filter_date: "invoice_date",
    });

    const lastWeekFilter = computed(() => {
      const commonFilter = filterOptions.value;
      const resultFilter = commonFilter.filter((item) => {
        return !item.field.match(options.value.filter_date);
      });
      return {
        ...setCommonFilter.value,
        filter_group: [
          ...setOtherFilter.value,
          ...resultFilter,
          {
            field: options.value.filter_date,
            value: settlementLastWeekDateString({}),
            condition: "gteq",
          },
          {
            field: options.value.filter_date,
            value: settlementLastWeekDateString({
              isStart: false,
            }),
            condition: "lt",
          },
        ],
      };
    });

    const lastMonthFilter = computed(() => {
      const commonFilter = filterOptions.value;
      const resultFilter = commonFilter.filter((item) => {
        return !item.field.match(options.value.filter_date);
      });
      return {
        ...setCommonFilter.value,
        filter_group: [
          ...setOtherFilter.value,
          ...resultFilter,
          {
            field: options.value.filter_date,
            value: settlementLastMonthDateString({}),
            condition: "gteq",
          },
          {
            field: options.value.filter_date,
            value: settlementLastMonthDateString({
              isStart: false,
            }),
            condition: "lt",
          },
        ],
      };
    });

    const setOtherFilter = computed(() => {
      return [];
    });

    const setCommonFilter = computed(() => {
      return {
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        sort_orders: sortOptions.value,
        filter_group: [...filterOptions.value, ...setOtherFilter.value],
      };
    });

    const isCanExport = computed((): boolean => {
      return checkPermissionFlag({ auth: ["export"] });
    });

    const getList = async () => {
      loading.value = true;
      const { data } = await ApiFinacialReport.getSsrReportList(
        setCommonFilter.value
      );
      loading.value = false;
      if (data.code == 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        tableData.value.forEach((item) => {
          item.downloadDisabled = false;
        });
        total.value = data.data.total;
      }
    };

    const onRowsSelectChange = (selection) => {
      let ids: number[] = [];
      selection.forEach((item) => {
        ids.push(item.id);
      });
      checkedInvoice.value = ids;
    };

    const filterExportType = (type, flag = true) => {
      let params = {};
      if (type === "all") {
        if (flag) {
          disabledExportAll.value = true;
          params = {
            ...setCommonFilter.value,
          };
        } else {
          disabledExportAll.value = false;
        }
      } else if (type === "selected") {
        if (flag) {
          if (checkedInvoice.value.length > 0) {
            let arr = [...filterOptions.value, ...setOtherFilter.value];
            arr.push({
              field: "id",
              value: checkedInvoice.value,
              condition: "in",
            });
            params = {
              filter_group: arr,
            };
            disabledExportSelected.value = true;
          } else {
            return false;
          }
        } else {
          disabledExportSelected.value = false;
        }
      } else if (type === "lastWeek") {
        if (flag) {
          disabledExportLastWeek.value = true;
          params = {
            filter_group: lastWeekFilter.value.filter_group,
          };
        } else {
          disabledExportLastWeek.value = false;
        }
      } else if (type === "lastMonth") {
        if (flag) {
          disabledExportLastMonth.value = true;
          params = {
            filter_group: lastMonthFilter.value.filter_group,
          };
        } else {
          disabledExportLastMonth.value = false;
        }
      }
      if (flag) {
        return params;
      }
    };

    const filesExport = async (type = "all") => {
      let idParams = filterExportType(type);
      const data = await ApiFinacialReport.exportSsrReport({
        ...idParams,
      });
      filterExportType(type, false);
      commonExportFile(data);
    };

    const fileExport = async (id, item) => {
      item.downloadDisabled = true;
      let arr = [...filterOptions.value, ...setOtherFilter.value];
      arr.push({
        field: "id",
        value: [id],
        condition: "in",
      });
      const data = await ApiFinacialReport.exportSsrReport({
        filter_group: arr,
      });
      item.downloadDisabled = false;
      commonExportFile(data);
    };

    const handleFilter = (val) => {
      console.log(val);
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (val[item] != "" && val[item] != null) {
            if (item.includes(options.value.filter_date)) {
              const isStart = item.includes("_start") ? true : false,
                date = isStart
                  ? commonChangeFilterAddDate(val[item])
                  : commonChangeFilterAddDate(val[item], 1);
              filterArr.push({
                field: options.value.filter_date,
                value: date,
                condition: isStart ? "gteq" : "lt",
              });
            } else if (item.includes("order_date")) {
              const isStart = item.includes("_start") ? true : false,
                date = isStart
                  ? commonChangeFilterAddDate(val[item])
                  : commonChangeFilterAddDate(val[item], 1);
              filterArr.push({
                field: "order_date",
                value: date,
                condition: isStart ? "gteq" : "lt",
              });
            } else {
              filterArr.push({
                field: item,
                value: val[item],
                condition: "eq",
              });
            }
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      updateList();
    };

    const handleFilterReset = () => {
      resetFilter();
      updateList();
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      updateList();
    };

    const updateList = () => {
      getList();
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      updateList();
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      updateList();
    };

    const debounceSearch = _.debounce(updateList, 1000);

    const searchItems = () => {
      debounceSearch();
    };

    const removeSearch = () => {
      search.value = "";
      updateList();
    };

    const init = () => {
      setModuleBCN(t, route);
      updateList();
    };

    onMounted(() => {
      init();
      // if (store.getters.getAliveList.length === 0) {
      //   init();
      // }
    });

    onActivated(() => {
      init();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      formatDate,
      settlementLastWeekDate,
      settlementLastMonthDate,
      getOCPSSSReceivablesStatus,
      loading,
      filterRef,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      options,
      isCanExport,
      disabledExportAll,
      disabledExportSelected,
      disabledExportLastWeek,
      disabledExportLastMonth,
      checkedInvoice,
      updateList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      onRowsSelectChange,
      filesExport,
      fileExport,
    };
  },
});
